<template>
  <div class="bg_F8F8F8">
    <Header :activeName="activeName" />
    <div class="mt_69 blue_box flex center">
      <img src="../../assets/images/job/job1.png" class="job_image" />
      <div class="ml_100">
        <div class="fc_2A2A2A fz_43 font_blod">找工作</div>
        <div class="mt_30 fc_2A2A2A fz_30">用惠开人才小程序</div>
        <div class="mt_60 fc_FFFFFF fz_18">小程序关注方式</div>
        <div class="text-center">
          <img src="../../assets/images/job/wx1.png" class="wx_image mt_10" />
          <div class="mt_10 fc_2A2A2A fz_12">
            打开“微信 - 发现 - 搜一搜”搜索
          </div>
        </div>
      </div>
    </div>

    <div class="grey_box flex center">
      <div>
        <div class="fc_2A2A2A fz_43 font_blod">简历｜Resume</div>
        <div class="mt_30 fc_2A2A2A fz_30">在线填写，生成专属简历</div>
      </div>
      <img src="../../assets/images/job/resume.png" class="job_image ml_100" />
    </div>
    <div class="blue_box flex center">
      <img src="../../assets/images/job/progress.png" class="job_image" />
      <div class="ml_100">
        <div class="fc_2A2A2A fz_43 font_blod">投递进展</div>
        <div class="mt_30 fc_2A2A2A fz_30">方便了解简历投递后进展情况</div>
      </div>
    </div>
    <div class="grey_box text-center">
      <div class="fc_2A2A2A fz_43 font_blod pt_90">
        更多优秀的企业在我们平台招聘人才
      </div>

      <!-- <div class="fc_2A2A2A fz_30 mt_30">
        百度、美团、红豆、建鼎都在使用惠开人才招人
      </div> -->
      <img
        src="../../assets/images/job/position.png"
        class="position_image mt_60"
      />
    </div>

    <Bottom />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Bottom from "@/components/bottom/index";
export default {
  components: {
    Header,
    Bottom,
  },
  data() {
    return {
      activeName: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fc_2A2A2A {
  color: #2a2a2a;
}
.fz_43 {
  font-size: 43px;
}
.home_title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #111c2f;
}
.blue_box {
  background-color: #6db0ff;
  width: 100%;
  height: 790px;
}
.job_image {
  width: 316px;
  height: 563px;
}
.ml_100 {
  margin-left: 100px;
}
.wx_image {
  width: 470px;
  height: 171px;
}
.grey_box {
  background-color: #f8f8f8;
  width: 100%;
  height: 790px;
}
.pt_90 {
  padding-top: 90px;
}
.position_image {
  width: 819px;
  height: 467px;
}
</style>
